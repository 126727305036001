<template>
  <div class="sis-content">
    <div class="sis-content__container container">
      <h1 class="sis-content__headline" v-t="'userManagement.headline'" />

      <div class="sis-content__action-bar">
        <sis-search v-model="searchTerm" />

        <jwl-button :to-route="{ name: 'userImport' }" icon-name="plus">
          {{$t('general.addNew')}}
        </jwl-button>
      </div>

      <div class="sis-content__divider" />

      <div class="sis-content__loading" v-if="loading">
        <common-icon icon-name="spinner-third" icon-style="duotone" margin-right />
        {{$t('userManagement.loadingData')}}
      </div>

      <sis-sortable-table
        v-if="users"
        table-theme="indigo"
        :table-head="tableHeadDefinition"
        :table-data="filteredUsers" />

      <common-error :jwl-error="error" v-if="error" />
    </div>
  </div>
</template>

<script>
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';
import JwlButton from '@/components/JwlButton.vue';
import SisSearch from '../components/SisSearch.vue';
import SisSortableTable from '../helper/sisSortableTable.vue';
import TableHead from '../helper/TableHead';

const CommonError = () => import('common-modules/src/components/CommonError.vue');

export default {
  components: {
    CommonError,
    CommonIcon,
    JwlButton,
    SisSearch,
    SisSortableTable,
  },
  data () {
    return {
      searchTerm: '',
      tableHeadDefinition: [
        new TableHead('general.lmsId', 'lmsId'),
        new TableHead('general.name', 'name'),
        new TableHead('general.email', 'email', true, 'email'),
        new TableHead('general.birthDate', 'birthDate', true, 'date'),
        new TableHead(null, null, false, 'link', 'userEdit', 'user'),
      ],
      users: null,
      loading: true,
      error: null,
    };
  },
  computed: {
    filteredUsers () {
      if (this.searchTerm) {
        return this.users.filter((user) => {
          const filterLmsId = user.lmsId.toLowerCase().includes(this.searchTerm.toLowerCase());
          const filterName = user.name.toLowerCase().includes(this.searchTerm.toLowerCase());
          return filterLmsId || filterName;
        });
      }
      return this.users;
    },
  },
  mounted () {
    this.$store.dispatch('getData', 'user/list')
      .then((data) => {
        this.users = data;
        this.loading = false;
      })
      .catch((e) => {
        this.error = e;
      });
  },
};
</script>
