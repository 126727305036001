















import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import CommonIcon from 'common-modules/src/components/CommonIcon.vue';

@Component({
  components: {
    CommonIcon,
  },
})
export default class SisSearch extends Vue {
  @Prop(String)
  value!: string

  searchTerm = '';

  created (): void {
    this.searchTerm = this.value;
  }
}
